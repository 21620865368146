import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  FormInstance,
  SignedStates,
  SigningPartyType
} from '@property-folders/contract';
import {
  FormTypes,
  mapSigningPartySourceTypeToCategoryRespectingOverride
} from '@property-folders/common/yjs-schema/property/form';
import React from 'react';
import { FullScreenLoader } from '~/components/loaders';
import { ContentCard } from '~/components/content-card';
import { Card, Col, Row } from 'react-bootstrap';
import { formatTimestamp } from '@property-folders/common/util/formatting';
import { Icon } from '@property-folders/components/dragged-components/Icon';

export function ViewSigning({ formCode, formId, portalId, timeZone, tableTitleEndButtons }: { formCode: string, formId: string, portalId: string, timeZone: string, tableTitleEndButtons?: React.ReactNode }) {
  const family = FormTypes[formCode].formFamily;
  const { value: formInstance, fullPath: formInstancePath } = useLightweightTransaction<FormInstance>({
    myPath: `formStates.${family}.instances.[${formId}]`,
    bindToMetaKey: true
  });
  const sessionTimeString = formInstance?.signing?.session?.timestamp
    ? formatTimestamp(formInstance.signing.session.timestamp, timeZone, true)
    : undefined;

  if (!formInstance) {
    return <FullScreenLoader />;
  }

  // note: this is just some placeholder content until the work item is done more thoroughly
  // need to detect primary purchaser and print special text for them.
  return <ContentCard>
    <Card.Header className='d-flex flex-row justify-content-between align-items-center'>
      {formInstance.signing?.state && SignedStates.has(formInstance.signing.state)
        ? <h4>Signed</h4>
        : <h4>Signing</h4>}
      {tableTitleEndButtons && <div className='d-flex flex-row justify-content-between align-items-center children-spacing-rem'>{tableTitleEndButtons}</div>}
    </Card.Header>
    <Card.Body>
      <p>We have invited all of the parties to sign the document electronically:</p>
      <div className='d-flex flex-column gap-4'>
        {(formInstance?.signing?.parties || []).filter(party => mapSigningPartySourceTypeToCategoryRespectingOverride(party.source) === 'purchaser').map((party, index) => {
          return <Row key={index}>
            <Col md={6} className='d-flex flex-column gap-1'>
              <span><b>{party.snapshot?.name}</b></span>
              <span className='text-decoration-underline'>{party.snapshot?.email}</span>
              <span>{party.snapshot?.phone}</span>
            </Col>
            <Col md={6} className='d-flex flex-row gap-1'>
              <div style={{ minWidth: '2rem' }}>{party.signedTimestamp && <Icon name={'check_circle'} style={{
                color: 'limegreen',
                fontSize: '2rem',
                marginTop: '-0.25rem'
              }}/>}</div>
              <div className='d-flex flex-column gap-1'>
                {party.signedTimestamp
                  ? <>
                    <span><b>Signed</b></span>
                    <span>Signed on {formatTimestamp(party.signedTimestamp, timeZone, false)}</span>
                  </>
                  : <>
                    <span><b>Pending</b></span>
                    {sessionTimeString && <span>Pending since {sessionTimeString}</span>}
                  </>}
                { party.snapshot?.isPrimary
                  ?<span>Sign via Purchaser portal</span>
                  : party.type === SigningPartyType.SignOnlineSms
                    ? <span>Sign via SMS link</span>
                    : <span>Sign via Email</span>}
              </div>
            </Col>
          </Row>;
        })}
      </div>
    </Card.Body>
  </ContentCard>;
}
