import { DefaultPageLayout } from '~/components/default-page-layout';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { Link, useAsyncValue, useNavigate, useParams } from 'react-router-dom';
import { ShortId } from '@property-folders/common/util/url';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  AuthorityParty,
  FileRef,
  FormCode,
  FormInstance,
  FormSigningState,
  FormStates,
  GetPurchaserPortalAuthenticatedResult,
  GetPurchaserPortalOfferSigningSessionResult,
  MaterialisedPropertyData,
  SigningParty,
  SigningPartySourceType,
  SigningPartyVerificationType,
  SigningStates,
  TransactionMetaData,
  YDocContentType
} from '@property-folders/contract';
import { PropertyRootKey, SignedStates } from '@property-folders/contract/yjs-schema/property';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { Button } from 'react-bootstrap';
import { FormContext } from '~/../../components/context/FormContext';

import {
  PurchaserEditableDocumentStatus,
  PurchaserPortalData,
  PurchaserSubmittedDocument
} from '@property-folders/contract/yjs-schema/purchaser-portal';
import { ContentCard } from '~/components/content-card';
import { OfferReviewPrimaryPurchaser } from '~/components/offer/review-primary-purchaser';
import { OfferOtherPurchasers } from '~/components/offer/other-purchasers';
import { OfferPrepareDetails } from '~/components/offer/prepare-details';
import { WaitForLoadedYDoc } from '@property-folders/components/context/YManagerContext';
import { FullScreenLoader, LoaderCard } from '~/components/loaders';
import { BasicYjsContext } from '~/context/basic-yjs-context';
import * as Y from 'yjs';
import { useSelector, useStore } from 'react-redux';
import {
  offerContractFormContext,
  offerFormContext,
  reviewOtherPurchasersFormContext,
  reviewPrimaryPurchaserFormContext
} from '~/form-context-definitions';
import { FormUtil } from '@property-folders/common/util/form';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ConfigureSigning } from '~/components/offer/configure-signing';
import { PurchaserPortalYjsDal } from '@property-folders/common/yjs-schema/purchaser-portal';
import { ViewSigning } from '~/components/offer/view-signing';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { useAuth } from '~/context/auth-context';
import { PurchaserPortalApi } from '~/api';
import { mapSigningPartySourceTypeToCategoryRespectingOverride,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import { SimpleCrumbs } from '~/components/simple-crumbs';
import { Predicate } from '@property-folders/common/predicate';
import { ViewSubmittedOfferPage } from '~/components/offer/view-submitted-offer-page';
import { useQueryParams } from '@property-folders/components/hooks/useQueryParams';
import { OfferMakingAnOffer } from '~/components/offer/making-an-offer';

type ResolvedSuspenseData = GetPurchaserPortalAuthenticatedResult;

export function PurchaserAuthenticatedOfferRoute() {
  const { headline } = useAsyncValue() as ResolvedSuspenseData;
  const { offerIdRaw, portalIdRaw } = useParams<{ offerIdRaw: string, portalIdRaw: string }>();
  const queryParams = useQueryParams();
  const fromSigning = queryParams.get('from') === 'signing';
  const offerId = ShortId.toUuid(offerIdRaw);
  const portalId = ShortId.toUuid(portalIdRaw);
  const navigate = useNavigate();
  const { value: portalRoot } = useLightweightTransaction<PurchaserPortalData>({ myPath: '' });
  const editableMatch = (portalRoot?.editableDocuments || []).find(d => d.documentId === offerId);
  const submittedMatch = (portalRoot?.submittedDocuments || []).find(d => d.documentId === offerId);
  const data = useAsyncValue() as ResolvedSuspenseData;

  const { ydoc: portalDoc } = useContext(YjsDocContext);

  const onTransitionToSigning = () => {
    if (!portalDoc) return;
    new PurchaserPortalYjsDal(portalDoc)
      .transitionEditableDocumentState(offerId, PurchaserEditableDocumentStatus.Signing);
  };

  const onTransitionToNone = () => {
    if (!portalDoc) return;
    new PurchaserPortalYjsDal(portalDoc)
      .transitionEditableDocumentState(offerId, PurchaserEditableDocumentStatus.Draft);
  };

  if (submittedMatch) {
    if (fromSigning) navigate('../../..', { relative: 'path' });
    return fromSigning
      ? <>Redirecting...</>
      : <ViewSubmittedOfferPage
        headline={data.headline}
        submittedMatch={submittedMatch}
        portalId={portalId}
        agencyCssOverrides={data.cssOverrides}
      />;
  }

  if (editableMatch) {
    const breadcrumbText = `${editableMatch.name} (${editableMatch.status})`;
    switch (editableMatch.status) {
      case PurchaserEditableDocumentStatus.Draft:
        // this can be editing details or signing configuration
        //   editing details:
        //     review primary purchaser details
        //     add other purchasers
        //     fill in offer [contract] details
        //   signing configuration:
        //     nicer screen for it. only configure other purchasers if any.
        return <WaitForLoadedYDoc
          yDocId={offerId}
          yDocType={YDocContentType.Property}
          loadingElement={<FullScreenLoader/>}
        >
          {(yDoc: Y.Doc) => <BasicYjsContext
            yDoc={yDoc}
            yDocId={offerId}
            rootKey={PropertyRootKey.Data.toString()}
            metaKey={PropertyRootKey.Meta.toString()}
          >
            <EditOfferPage
              headline={headline!}
              portalId={portalId}
              onTransitionToSigning={onTransitionToSigning}
              breadcrumbText={breadcrumbText}
            />
          </BasicYjsContext>}
        </WaitForLoadedYDoc>;
      case PurchaserEditableDocumentStatus.Signing:
        return <WaitForLoadedYDoc
          yDocId={offerId}
          yDocType={YDocContentType.Property}
          loadingElement={<FullScreenLoader/>}
        >
          {(yDoc: Y.Doc) => <BasicYjsContext
            yDoc={yDoc}
            yDocId={offerId}
            rootKey={PropertyRootKey.Data.toString()}
            metaKey={PropertyRootKey.Meta.toString()}
          >
            <ViewSigningPage
              headline={headline!}
              portalId={portalId}
              onNoSigningSession={onTransitionToNone}
              breadcrumbText={breadcrumbText}
            />
          </BasicYjsContext>}
        </WaitForLoadedYDoc>;
      case PurchaserEditableDocumentStatus.Cancelled:
        return <ContentCard>
          todo: implement cancelled
        </ContentCard>;
    }
  }

  return <ContentCard>
    Invalid offer state
  </ContentCard>;
}

function ViewSigningPage({ headline, portalId, onNoSigningSession, breadcrumbText }: {
  headline: string,
  portalId: string,
  onNoSigningSession: () => void,
  breadcrumbText: string
}) {
  const navigate = useNavigate();
  const data = useAsyncValue() as ResolvedSuspenseData;
  const { docName: propertyId } = useContext(YjsDocContext);
  const [signingState, setSigningState] = useState<GetPurchaserPortalOfferSigningSessionResult | undefined>(undefined);
  const { value: formStates } = useLightweightTransaction<FormStates>({
    myPath: 'formStates',
    bindToMetaKey: true
  });
  const [errorMessage, setErrorMessage] = useState('');
  const formInstance = useMemo(() => {
    if (!formStates) return undefined;
    const family = formStates[FormCode.RSC_ContractOfSale] || formStates[FormCode.OfferToPurchase];
    if (!family?.instances?.length) return undefined;
    return family.instances.at(0);
  }, [formStates]);
  const contractMode = formInstance?.formCode === FormCode.RSC_ContractOfSale;

  useEffect(() => {
    if (formInstance && !formInstance.signing?.session?.id) {
      onNoSigningSession();
      return;
    }
    if (!formInstance?.signing?.session?.id) return;
    if (!propertyId) return;
    PurchaserPortalApi.getSigningSessionInfo(portalId, propertyId, formInstance.signing.session.id)
      .then(response => {
        setSigningState(response || { status: 'failed', statusMessage: 'failed to load' });
      })
      .catch(err => {
        console.error(err);
        navigate('..', { relative: 'route' });
      });
  }, [!!formInstance, propertyId, formInstance?.formCode, formInstance?.id, formInstance?.signing?.session?.id]);

  const voidSigning = async () => {
    if (!propertyId) return;
    if (!formInstance?.signing?.session?.id) return;
    setErrorMessage('');
    try {
      await PurchaserPortalApi.postVoidSigningSession(portalId, propertyId, formInstance.signing.session.id);
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage('Unexpected error.');
    }
  };

  useEffect(() => {
    if (signingState?.signingUrl) {
      window.location.href = signingState.signingUrl;
    }
  }, [signingState?.signingUrl]);

  if (!(formInstance && signingState)) {
    return <FullScreenLoader/>;
  }

  if (signingState?.signingUrl) {
    return <FullScreenLoader/>;
  }

  return <DefaultPageLayout
    title={'Prepare an offer'}
    subtitle={[<SimpleCrumbs>
      <Link key='backlink' to={'..'} relative={'route'}>{headline}</Link>
      <span key='crumb'>{breadcrumbText}</span>
    </SimpleCrumbs>]}
    agencyCssOverrides={data.cssOverrides}
  >
    <FormContext.Provider
      value={contractMode
        ? offerContractFormContext
        : offerFormContext}
    >
      <ViewSigning
        key='content'
        formCode={formInstance?.formCode}
        formId={formInstance?.id}
        portalId={portalId}
        timeZone={'Australia/Adelaide'}
        tableTitleEndButtons={<>
          {errorMessage
            ? <span key='error' className='text-danger'>{errorMessage}</span>
            : undefined}
          {formInstance.signing?.state && !SignedStates.has(formInstance.signing.state)
            ? <AsyncButton key='void' variant='danger' onClick={voidSigning}>Cancel signing</AsyncButton>
            : undefined}
        </>}
      />
    </FormContext.Provider>
  </DefaultPageLayout>;
}

function EditOfferPage({ headline, portalId, onTransitionToSigning, breadcrumbText }: {
  headline: string,
  portalId: string,
  onTransitionToSigning: () => void,
  breadcrumbText: string
}) {
  const store = useStore();
  const data = useAsyncValue() as ResolvedSuspenseData;
  const { sessionInfo, loaded } = useAuth();
  // editing steps:
  // 1 = review primary purchaser
  // 2 = review other purchasers
  // 3 = fill in document
  const [editingStep, setEditingStep] = useState(1);
  const { ydoc, awareness, docName, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const propertyId = docName;
  const {
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const { value: formStates } = useLightweightTransaction<FormStates>({
    myPath: 'formStates',
    bindToMetaKey: true
  });

  // Migration/backstop flagging that this is a portal property, ideally this would have been set at
  // property creation time
  const { value: isPortalMember } = useLightweightTransaction<string>({
    myPath: 'isPortalMember',
    bindToMetaKey: true
  });
  useEffect(()=>{
    if (isPortalMember) return;
    metaBinder?.update(draft=>{
      draft.isPortalMember = true;
    });
  }, [portalId]);
  // END Migration/backstop on setting portal property flag

  const formInstance = useMemo(() => {
    if (!formStates) return undefined;
    const family = formStates[FormCode.RSC_ContractOfSale] || formStates[FormCode.OfferToPurchase];
    if (!family?.instances?.length) return undefined;
    return family.instances.at(0);
  }, [formStates]);
  const contractMode = formInstance?.formCode === FormCode.RSC_ContractOfSale;
  // note: not form specific, just grabs everything
  const focusErrList = useSelector<any, string[]>((state: any) => (Object.values(state?.validation?.focusErrList?.[docName ?? '']?.[transactionRootKey ?? ''] || {}).flat() as string[]));
  const editingStepValid = !focusErrList.length;
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const nextEditingStepHandler = () => {
    if (editingStep === 4) return;
    if (!editingStepValid) return;

    setEditingStep(editingStep + 1);
  };
  const previousEditingStepHandler = () => {
    if (editingStep === 1) return;

    setEditingStep(editingStep - 1);
  };

  const startSigningHandler = async () => {
    if (!formInstance) return;
    if (!portalId) return;
    if (!propertyId) return;
    setSubmitting(true);
    setErrorMessage('');
    try {
      await PurchaserPortalApi.postStartSigningSession(portalId, propertyId);
      // server-side changes the signing state, which will trigger a page change/nav elsewhere. nothing to do here.
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage('something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const startSigningConfig = () => {
    if (!editingStepValid) return;
    if (!formInstance) return;
    if (!loaded) return;
    if (!metaBinder) return;
    if (!dataBinder) return;
    const { formCode, id: formId } = formInstance;
    FormUtil.transitionSigningState({
      formCode,
      formId,
      metaBinder,
      dataBinder,
      sessionInfo,
      store
    }, {
      to: FormSigningState.Configuring
      // Portal does not pay attention to entity signing options, in particular for automatic service of Form 1, which is also not for the purchaser to decide
    });

    const postMeta = metaBinder.get();
    const postData = dataBinder.get();
    const primaryPurchaser = postData.primaryPurchaser;
    const purchasers = postData.purchasers || [];
    if (!primaryPurchaser) return;

    const postInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, postMeta);
    if (!postInstance) return;

    const nonPrimaryPartyIds = new Set((postInstance.signing?.parties || [])
      .filter(party => mapSigningPartySourceTypeToCategoryRespectingOverride(party.source) === 'purchaser')
      .filter(party => !isPartyPrimaryPurchaser(primaryPurchaser, party, purchasers))
      .map(party => party.id));

    if (nonPrimaryPartyIds.size === 0) {
      // bypass configuration. let's gooooo
      startSigningHandler().finally(console.warn);
    } else {
      metaBinder.update(state => {
        const form = FormUtil.getFormState(formCode, formId, state);
        if (!form?.signing?.parties) return;

        for (const party of form.signing.parties) {
          if (!nonPrimaryPartyIds.has(party.id)) continue;
          const sourceData = purchasers.find(p => p.id === party.source.id);
          if (!sourceData) continue;
          switch (party.source.type) {
            case SigningPartySourceType.Purchaser:
            case SigningPartySourceType.PurchaserFirstParty:
              if (sourceData.phone1) {
                party.verification = { type: SigningPartyVerificationType.Sms };
              }
              break;
            case SigningPartySourceType.PurchaserSecondParty:
              if (sourceData.phone2) {
                party.verification = { type: SigningPartyVerificationType.Sms };
              }
              break;
          }
        }
      });
    }
  };

  const signingState = formInstance?.signing?.state || FormSigningState.None;
  useEffect(() => {
    if (SigningStates.has(signingState)) {
      onTransitionToSigning();
    }
  }, [signingState]);

  // note: initiating signing requires setting portal doc state as well as property doc state.
  // so we may need to do something funky when signing config is completed.

  if (!(ydoc && awareness && formInstance)) {
    return <FullScreenLoader/>;
  }

  const navigationButtons = useMemo(() => {
    return <React.Fragment key='after-title'>
      {editingStep <= 1
        ? undefined
        : <Button key='previous' variant='outline-secondary' onClick={previousEditingStepHandler}>Previous</Button>}
      {editingStep >= 4
        ? <Button key='signing' disabled={!editingStepValid} onClick={startSigningConfig}>Signing</Button>
        : <Button key='next' disabled={!editingStepValid} onClick={nextEditingStepHandler}>Next</Button>}
    </React.Fragment>;
  }, [editingStep, editingStepValid]);

  const contractSpecial = dataBinder?.get()?.contractSpecial;

  switch (signingState) {
    case FormSigningState.None:
      return <DefaultPageLayout
        title='Prepare an offer'
        subtitle={[<SimpleCrumbs key='crumbs'>
          <Link key='backlink' to={'..'} relative={'route'}>{headline}</Link>
          <span key='crumb'>{breadcrumbText}</span>
        </SimpleCrumbs>]}
        agencyCssOverrides={data.cssOverrides}
      >
        {editingStep === 1 &&
          <OfferMakingAnOffer
            contractMode={contractMode}
            footer={navigationButtons}
          />}
        {editingStep === 2 &&
          <FormContext.Provider
            value={reviewPrimaryPurchaserFormContext}
          >
            {/*todo: readonly email/phone*/}
            <OfferReviewPrimaryPurchaser
              footer={navigationButtons}/>
          </FormContext.Provider>}
        {editingStep === 3 &&
          <FormContext.Provider
            value={reviewOtherPurchasersFormContext}
          >
            <OfferOtherPurchasers
              footer={navigationButtons}/>
          </FormContext.Provider>}
        {editingStep === 4 &&
          <FormContext.Provider
            value={contractMode
              ? offerContractFormContext
              : offerFormContext}
          >
            <OfferPrepareDetails
              contractMode={contractMode}
              footer={navigationButtons}
              hideFinance={contractSpecial?.hideSection || contractSpecial?.hideFinance || contractSpecial?.financePermitted === false}
              hidePurchaserSale={contractSpecial?.hideSection || contractSpecial?.hideSaleRequired || contractSpecial?.purchaserSalePermitted === false}
            />
          </FormContext.Provider>}
      </DefaultPageLayout>;
    case FormSigningState.Configuring:
      return <ConfiguringPage
        formInstance={formInstance}
        headline={headline}
        onStartSigning={startSigningHandler}
        errorMessage={errorMessage}
        submitting={submitting}
      />;
    default:
      return <DefaultPageLayout
        agencyCssOverrides={data.cssOverrides}
        title={'Prepare an offer'}
        subtitle={[<Link key='backlink' to={'..'} relative={'route'}>{headline}</Link>]}
      >
        {/*nothing happens here, because we're transitioning to a different state*/}
        <FullScreenLoader/>
      </DefaultPageLayout>;
  }
}

function ConfiguringPage({
  formInstance,
  headline,
  submitting,
  errorMessage,
  onStartSigning
}: {
  formInstance: FormInstance,
  headline: string,
  submitting: boolean,
  errorMessage: string,
  onStartSigning: () => void
}) {
  const store = useStore();
  const data = useAsyncValue() as ResolvedSuspenseData;
  const { sessionInfo, loaded } = useAuth();
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const {
    binder: metaBinder
  } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const {
    binder: dataBinder
  } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);

  const cancelSigningConfig = () => {
    if (!formInstance) return;
    if (!loaded) return;
    const { formCode, id: formId } = formInstance;
    FormUtil.transitionSigningState({
      formCode,
      formId,
      metaBinder,
      dataBinder,
      sessionInfo,
      store
    }, {
      to: FormSigningState.None
    });
  };

  return <DefaultPageLayout
    agencyCssOverrides={data.cssOverrides}
    title='Prepare an offer'
    subtitle={[<Link key='backlink' to={'..'} relative={'route'}>{headline}</Link>]}
  >
    <FormContext.Provider
      value={formInstance.formCode === FormCode.RSC_ContractOfSale
        ? offerContractFormContext
        : offerFormContext}
    >
      {!!submitting && <LoaderCard title='Signing'/>}
      {!submitting && <ConfigureSigning
        key='content'
        formCode={formInstance.formCode}
        formId={formInstance.id}
        onStartSigning={onStartSigning}
        initialErrorMessage={errorMessage}
        tableTitleEndButtons={<Button
          key='cancel'
          variant='outline-secondary'
          onClick={cancelSigningConfig}
        >Cancel signing</Button>}
      />}
    </FormContext.Provider>
  </DefaultPageLayout>;
}

export function getSubmittedDocumentFileId(doc: PurchaserSubmittedDocument): FileRef | undefined {
  // for offers this should be the completed file id since only purchasers are required to sign
  // for contracts this should be the latest intermediate file id since the document technically isn't completed without vendor signatures
  const formStates = doc.snapshot.meta.formStates;
  if (!formStates) return undefined;
  for (const formState of Object.values(formStates)) {
    if (!formState.instances?.length) continue;
    for (const instance of formState.instances) {
      const session = instance.signing?.session;
      if (!session) continue;

      if (session.completedFile) {
        if (Array.isArray(session.completedFile)) {
          return session.completedFile.at(0);
        } else {
          return session.completedFile;
        }
      }

      if (session.intermediateFiles?.length) {
        return session.intermediateFiles.at(-1);
      }
    }
  }
  return undefined;
}

export function getSubmittedDocumentFileName(headline: string, doc: PurchaserSubmittedDocument, suffix?: string): string {
  return `${[
    headline,
    doc.name,
    suffix
  ].filter(Predicate.isNotNull)
    .join(' - ')}.pdf`;
}

function isPartyPrimaryPurchaser(primaryPurchaserId: string, party: SigningParty, purchasers: AuthorityParty[]) {
  if (party.source.id !== primaryPurchaserId) return false;
  const match = purchasers.find(p => p.id === primaryPurchaserId);
  if (!match) return false;

  switch (party.source.type) {
    case SigningPartySourceType.Purchaser:
    case SigningPartySourceType.PurchaserFirstParty:
      return match.primarySubcontact !== 1;
    case SigningPartySourceType.PurchaserSecondParty:
      return match.primarySubcontact === 1;
  }
  return false;
}
